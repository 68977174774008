<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong> Laporan Perjalanan Dinas </strong><small>Detail Data</small>
            <!-- <CButton class="float-right btn-sm" color="warning" to="/surattugas">Lihat PDF</CButton> -->
          </CCardHeader>
          <CCardBody>
          <CAlert
            color="danger"
            closeButton
            :show.sync="alert1"
          >
            <b>{{ notif }}</b>
          </CAlert>
            <CForm @submit.prevent="updateUpload">
            <CRow>
              <CCol sm="9">
                <CInput
                  v-model="no_surat_tugas"
                  
                  label="Nomor Surat Tugas"
                  placeholder="Input Nomor Surat Tugas"
                  readonly
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="tgl_surat_tugas"
                  type="date"
                  label="Tanggal Surat Tugas"
                  placeholder="Input Tanggal Surat Tugas"
                  readonly
                />
              </CCol>
              <CCol sm="12">
                <CTextarea
                  v-model="uraian_surat_tugas"
                  label="Uraian Surat Tugas"
                  placeholder="Input Uraian Surat Tugas"
                  readonly
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="tgl_pelaksanaan"
                  type="date"
                  label="Tanggal pelaksanaan"
                  placeholder="Input Tanggal pelaksanaan"
                  readonly
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="jumlah_hari_pelaksanaan"
                  type="number"
                  label="Jumlah Hari Pelaksanaan"
                  placeholder="Input Jumlah Hari Pelaksanaan"
                  readonly
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="dasar_surat_tugas"
                  label="Dasar Surat Tugas"
                  placeholder="Input Dasar Surat Tugas"
                  readonly
                />
              </CCol>              
              <CCol sm="12">
                <CTextarea
                  v-model="nama_pelaksana"
                  label="Nama Pelaksana"
                  placeholder="Input Nama Pelaksana"
                  readonly
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="pejabat_ttd"
                  label="Nama Pejabat TTD Surat Tugas"
                  placeholder="Input Nama Pejabat"
                  readonly
                />
              </CCol> 
              <CCol sm="6">
                <div class="form-group">
                <label>Jenis Tujuan Dinas</label>
                  <select v-model="jenis_tujuan" class="form-control" readonly>
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option value="1">Dalam Provinsi</option>
                    <option value="2">Luar Provinsi</option>
                    <option value="3">Luar Negeri</option>
                  </select>
                </div>
              </CCol> 

              <CCol sm="6">
                <div class="form-group">
                  <label>Surat Tugas </label><br/>                  
                  <div class="input-group mb-3">
                    <input type="text" class="form-control"  v-model="placeholder"  readonly />
                    <div class="input-group-append">
                      <a class="btn btn-light" :href="placeholder" target="_blank">Unduh</a>
                    </div>
                  </div>
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Surat Perjalanan Dinas & Lampirannya *dalam 1 file </label><br/>                  
                  <div class="input-group mb-3">
                    <input type="text" class="form-control"  v-model="placeholder2"  readonly />
                    <div class="input-group-append">
                      <a class="btn btn-light" :href="placeholder2" target="_blank">Unduh</a>
                    </div>
                  </div>
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Laporan Kegiatan </label><br/>                  
                  <div class="input-group mb-3">
                    <input type="text" class="form-control"  v-model="placeholder3"  readonly />
                    <div class="input-group-append">
                      <a class="btn btn-light" :href="placeholder3" target="_blank">Unduh</a>
                    </div>
                  </div>
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Dasar dalam Perjalanan Dinas (nodin/undangan)*apabila ada </label><br/>                  
                  <div class="input-group mb-3">
                    <input type="text" class="form-control"  v-model="placeholder4"  readonly />
                    <div class="input-group-append">
                      <a class="btn btn-light" :href="placeholder4" target="_blank">Unduh</a>
                    </div>
                  </div>
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Bukti Pembayaran Tiket & Nominal *apabila ada </label><br/>       
                  <div class='row'>
                    <div class='col-7'>
                      <div class="input-group mb-3">
                        <input type="text" class="form-control"  v-model="placeholder5"  readonly />
                        <div class="input-group-append">
                            <a class="btn btn-light" :href="placeholder5" target="_blank">Unduh</a>
                        </div>
                      </div>
                    </div>
                    <div class='col-5'>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Rp</span>
                        </div>
                        <input type="text" placeholder="0" readonly v-model="bukti_pembayaran_tiket_nominal"  class="form-control" aria-label="Nominal (Rupiah)">
                      </div>
                    </div>
                  </div>           
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Bukti Pembayaran Penginapan & Nominal *apabila ada </label><br/>       
                  <div class='row'>
                    <div class='col-7'>
                      <div class="input-group mb-3">
                        <input type="text" class="form-control"  v-model="placeholder6"  readonly />
                        <div class="input-group-append">
                            <a class="btn btn-light" :href="placeholder6" target="_blank">Unduh</a>
                        </div>
                      </div>
                    </div>
                    <div class='col-5'>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Rp</span>
                        </div>
                        <input type="text" placeholder="0" readonly v-model="bukti_pembayaran_penginapan_nominal"  class="form-control" aria-label="Nominal (Rupiah)" />
                      </div>
                    </div>
                  </div>           
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Bukti Pembayaran Sewa Kendaraan & Nominal *apabila ada </label><br/>       
                  <div class='row'>
                    <div class='col-7'>
                      <div class="input-group mb-3">
                        <input type="text" class="form-control"  v-model="placeholder7"  readonly />
                        <div class="input-group-append">
                            <a class="btn btn-light" :href="placeholder7" target="_blank">Unduh</a>
                        </div>
                      </div>
                    </div>
                    <div class='col-5'>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Rp</span>
                        </div>
                        <input type="text" placeholder="0" readonly v-model="bukti_pembayaran_sewa_kendaraan_nominal"  class="form-control" aria-label="Nominal (Rupiah)">
                      </div>
                    </div>
                  </div>           
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Bukti Pembayaran BBM & Nominal *apabila ada </label><br/>       
                  <div class='row'>
                    <div class='col-7'>
                      <div class="input-group mb-3">
                        <input type="text" class="form-control"  v-model="placeholder8"  readonly />
                        <div class="input-group-append">
                            <a class="btn btn-light" :href="placeholder8" target="_blank">Unduh</a>
                        </div>
                      </div>
                    </div>
                    <div class='col-5'>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Rp</span>
                        </div>
                        <input type="text" placeholder="0" readonly v-model="bukti_pembayaran_bbm_nominal"  class="form-control" aria-label="Nominal (Rupiah)">
                      </div>
                    </div>
                  </div>           
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Bukti Pembayaran Transport Lainnya & Nominal *apabila ada </label><br/>       
                  <div class='row'>
                    <div class='col-7'>
                      <div class="input-group mb-3">
                        <input type="text" class="form-control"  v-model="placeholder9"  readonly />
                        <div class="input-group-append">
                            <a class="btn btn-light" :href="placeholder9" target="_blank">Unduh</a>
                        </div>
                      </div>
                    </div>
                    <div class='col-5'>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Rp</span>
                        </div>
                        <input type="text" placeholder="0" readonly v-model="bukti_pembayaran_transport_lainnya_nominal"  class="form-control" aria-label="Nominal (Rupiah)">
                      </div>
                    </div>
                  </div>           
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Bukti Pembayaran Taxi & Nominal *apabila ada </label><br/>       
                  <div class='row'>
                    <div class='col-7'>
                      <div class="input-group mb-3">
                        <input type="text" class="form-control"  v-model="placeholder10"  readonly />
                        <div class="input-group-append">
                            <a class="btn btn-light" :href="placeholder10" target="_blank">Unduh</a>
                        </div>
                      </div>
                    </div>
                    <div class='col-5'>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Rp</span>
                        </div>
                        <input type="text" placeholder="0" readonly v-model="bukti_pembayaran_taxi_nominal"  class="form-control" aria-label="Nominal (Rupiah)">
                      </div>
                    </div>
                  </div>           
                </div>  
              </CCol>
              <CCol sm="4">
                <div class="form-group">
                  <label>Total Nominal</label>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Rp</span>
                    </div>
                    <input type="text" placeholder="0" readonly :value="total"  class="form-control" aria-label="Nominal (Rupiah)">
                  </div>
                </div>
              </CCol>
              <CCol sm="4"  v-if="level=='Admin'">  
                <div class="form-group">
                <label>Status Berkas</label>
                  <select v-model="status_review" class="form-control">
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option value="0">Belum Disetujui</option>
                    <option value="1">Disetujui</option>
                    <option value="2">Pending</option>
                  </select>
                </div>
              </CCol>     
              <CCol sm="4"  v-if="level=='Admin'">             
                <div class="form-group">
                <label>Akun Sakti</label>
                  <select class="form-control" v-model="nama_upt">
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option v-for="r in upts" :value="r.id_upt">{{r.nama_upt}} </option>
                  </select>
                </div>
              </CCol> 
              <CCol sm="12" v-if="level=='Admin'">
                <CTextarea
                  v-model="notif"
                  label="Notifikasi"
                  placeholder=""
                />
              </CCol>            
            </CRow>      
              <div class="form-actions float-right">
                <CButton type="submit" color="primary" style='margin-right:15px'  v-if="level=='Admin'">Simpan</CButton>
                <CButton color="warning" to="/dashboard/pie">Kembali</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>    
  
</template>

<script>

let user = JSON.parse(localStorage.getItem('user'));
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      alert1 : false,
      notif : "",
      nama_upt : "",
      status_review : "",
      no_surat_tugas : "",
      tgl_surat_tugas : "",
      uraian_surat_tugas : "",
      tgl_pelaksanaan : "",
      jumlah_hari_pelaksanaan : 0,
      nama_pelaksana : "",
      dasar_surat_tugas : "",
      pejabat_ttd : "",
      jenis_tujuan : "",
      file_surat_tugas_text : "",
      file_surat_tugas : "",
      file_perjadin_text : "",
      file_perjadin : "",
      file_laporan_kegiatan_text : "",
      file_laporan_kegiatan : "",
      file_dasar_perjadin_text : "",
      file_dasar_perjadin : "",
      file_bukti_pembayaran_tiket_text : "",
      file_bukti_pembayaran_tiket : "",
      file_bukti_pembayaran_penginapan_text : "",
      file_bukti_pembayaran_penginapan : "",
      file_bukti_pembayaran_sewa_kendaraan_text : "",
      file_bukti_pembayaran_sewa_kendaraan : "",
      file_bukti_pembayaran_bbm_text : "",
      file_bukti_pembayaran_bbm : "",
      file_bukti_pembayaran_transport_lainnya_text : "",
      file_bukti_pembayaran_transport_lainnya : "",
      file_bukti_pembayaran_taxi_text : "",
      file_bukti_pembayaran_taxi : "",
      bukti_pembayaran_tiket_nominal : "0",
      bukti_pembayaran_penginapan_nominal : "0",
      bukti_pembayaran_sewa_kendaraan_nominal : "0",
      bukti_pembayaran_bbm_nominal : "0",
      bukti_pembayaran_transport_lainnya_nominal : "0",
      bukti_pembayaran_taxi_nominal : "0",
      total_nominal : 0,
      placeholder : "Pilih File",
      placeholder2 : "Pilih File",
      placeholder3 : "Pilih File",
      placeholder4 : "Pilih File",
      placeholder5 : "Pilih File",
      placeholder6 : "Pilih File",
      placeholder7 : "Pilih File",
      placeholder8 : "Pilih File",
      placeholder9 : "Pilih File",
      placeholder10 : "Pilih File",
      selected: [], // Must be an array reference!
      // surattugass: [],
      upts: [],
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  created(){
      let us = JSON.parse(this.$store.state.users);
      this.level = us.level
  },
  mounted(){
  this.loadData();
  if(this.notif){
    alert1=true
  }
  this.showSurattugas();
  },
  computed: {
    total: function() {
      let calculatedTotal = +this.bukti_pembayaran_bbm_nominal + +this.bukti_pembayaran_penginapan_nominal + +this.bukti_pembayaran_sewa_kendaraan_nominal + +this.bukti_pembayaran_taxi_nominal + +this.bukti_pembayaran_tiket_nominal + +this.bukti_pembayaran_transport_lainnya_nominal;
      this.total_nominal = calculatedTotal;
      
      return calculatedTotal;
    }
  },
  methods: {
    
    loadData: function(event){
      
      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"upts")
        .then((response) => {
              this.upts=response.data;
        })
    },  

    updateUpload: function(event){

      const surattugas = { 
                          status_review: this.status_review, 
                          nama_upt: this.nama_upt,
                          notif: this.notif
                          };

      // alert(JSON.stringify(pekerjaan));
      axios.put(process.env.VUE_APP_BASE_URL+"surattugas/"+this.$route.params.id_surat_tugas, surattugas)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.modalSuccess = true;
              if(this.jenis_tujuan=='1')
                this.$router.push('/perjadin/laporandalamprovinsi');
              if(this.jenis_tujuan=='2')
                this.$router.push('/perjadin/laporanluarprovinsi');;
              if(this.jenis_tujuan=='3')
                this.$router.push('/perjadin/laporanluarnegeri');
            }
            else{
              this.modalError = true;
            }            
        })
    },
    showSurattugas: function(event){
    
      const surattugas = { 
                          status_review: this.status_review, 
                          no_surat_tugas: this.no_surat_tugas, 
                          tgl_surat_tugas: this.tgl_surat_tugas, 
                          uraian_surat_tugas: this.uraian_surat_tugas,
                          tgl_pelaksanaan: this.tgl_pelaksanaan,
                          jumlah_hari_pelaksanaan: this.jumlah_hari_pelaksanaan,
                          nama_pelaksana: this.nama_pelaksana,
                          dasar_surat_tugas: this.dasar_surat_tugas,
                          jenis_tujuan: this.jenis_tujuan,
                          notif: this.notif,
                          pejabat_ttd: this.pejabat_ttd
                          };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"surattugas/"+this.$route.params.id_surat_tugas)
        .then((response) => {            
              this.status_review=response.data.status_review;
              this.no_surat_tugas=response.data.no_surat_tugas;
              this.tgl_surat_tugas=response.data.tgl_surat_tugas;
              this.uraian_surat_tugas=response.data.uraian_surat_tugas;
              this.tgl_pelaksanaan=response.data.tgl_pelaksanaan;
              this.jumlah_hari_pelaksanaan=response.data.jumlah_hari_pelaksanaan;
              this.dasar_surat_tugas=response.data.dasar_surat_tugas;
              this.nama_pelaksana=response.data.nama_pelaksana;
              this.pejabat_ttd=response.data.pejabat_ttd;
              this.jenis_tujuan=response.data.jenis_tujuan;
              this.notif=response.data.notif;
              this.alert1=response.data.notif ?? false; 
              this.placeholder=response.data.file_surat_tugas ?? "N/A";
              this.placeholder2=response.data.file_perjadin ?? "N/A";
              this.placeholder3=response.data.file_laporan_kegiatan ?? "N/A";
              this.placeholder4=response.data.file_dasar_perjadin ?? "N/A";
              this.placeholder5=response.data.file_bukti_pembayaran_tiket ?? "N/A";
              this.placeholder6=response.data.file_bukti_pembayaran_penginapan ?? "N/A";
              this.placeholder7=response.data.file_bukti_pembayaran_sewa_kendaraan ?? "N/A";
              this.placeholder8=response.data.file_bukti_pembayaran_bbm ?? "N/A";
              this.placeholder9=response.data.file_bukti_pembayaran_transport_lainnya ?? "N/A";
              this.placeholder10=response.data.file_bukti_pembayaran_taxi ?? "N/A";
              this.bukti_pembayaran_bbm_nominal=response.data.bukti_pembayaran_bbm_nominal;
              this.bukti_pembayaran_penginapan_nominal=response.data.bukti_pembayaran_penginapan_nominal;
              this.bukti_pembayaran_sewa_kendaraan_nominal=response.data.bukti_pembayaran_sewa_kendaraan_nominal;
              this.bukti_pembayaran_taxi_nominal=response.data.bukti_pembayaran_taxi_nominal;
              this.bukti_pembayaran_tiket_nominal=response.data.bukti_pembayaran_tiket_nominal;
              this.bukti_pembayaran_transport_lainnya_nominal=response.data.bukti_pembayaran_transport_lainnya_nominal;
        })
    }
  }
}
</script>